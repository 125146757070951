/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
import React, {useState, useContext} from 'react'
import {Link} from 'gatsby'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Input from 'semantic-ui-react/dist/commonjs/elements/Input'
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import Reaptcha from 'reaptcha'
import styled from 'styled-components'
import useForm from '../Hooks/useForm'
import AuthContext from '../Context/AuthContext'

const FormStyles = styled.div`
  .ui.label.label {
    min-width: 200px;
    text-transform: uppercase;
    display: grid;
    align-items: center;
    margin: 0;
    border-radius: 3px 0 0 3px;
  }
  .form-footer {
    text-align: center;
  }
  .ui.fluid.labeled {
    input {
      border-radius: 0 3px 3px 0;
    }
  }
  .ui.fluid.error {
    input {
      border-color: #d4909e;
      border-bottom-right-radius: 0;
    }
    .label {
      background-color: #d4909e;
      border-bottom-left-radius: 0;
    }
  }
  @media (max-width: 500px) {
    .ui.fluid.input {
      display: grid;
      grid-template-columns: 1fr;
    }
    .ui.label.label {
      min-width: auto;
      border-top-right-radius: 5px !important;
      border-top-left-radius: 5px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .ui.form .field .ui.input input {
      width: 100% !important;
      border: 1px solid rgba(34, 36, 38, 0.15) !important;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    .ui.form .field .ui.input.error input {
      border-color: #d4909e !important;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`
const RecaptchaWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  padding: 10px 0 20px 0;
`

// These callback functions are optional
const RegisterForm = ({registerCallback = () => {}}) => {
  const [loading, setLoading] = useState(false)
  const [captcha, setCaptcha] = useState(false)
  const [hidePassword, setHidePassword] = useState(true)
  const [captchaError, setCaptchaError] = useState('')
  const [apiError, setApiError] = useState(false)
  const {performRegister, loggedInState} = useContext(AuthContext)

  const formRegister = async () => {
    if (!captcha) {
      setCaptchaError(
        'Please click the checkbox and solve Captcha before continuing.',
      )
      return
    }
    setCaptchaError('')

    const {email, password, first_name, last_name, bday} = values
    performRegister(
      email,
      password,
      first_name,
      last_name,
      bday,
      setLoading,
      setApiError,
      registerCallback,
    )
  }

  const onCaptchaVerified = () => {
    setCaptcha(true)
  }

  const {values, handleChange, handleSubmit, errors} = useForm(
    formRegister,
    validate,
  )

  const handleErrors = error => {
    if (error.content) {
      return (
        <Message error>
          <Message.Header>{error.header}</Message.Header>
          <p>
            {'Email already exists. Please try '}
            <Link to="/autres">Reset Password</Link>
            {'.'}
          </p>
        </Message>
      )
    }

    return (
      <Message error header="Sorry" content="Cannot register at this time." />
    )
  }

  return (
    <FormStyles>
      <Form
        onSubmit={handleSubmit}
        loading={loading || loggedInState === null}
        error={!!errors}
      >
        {apiError && handleErrors(apiError)}
        <Segment>
          <Form.Field>
            <Input
              label="Email"
              id="email"
              fluid
              name="email"
              type="email"
              autoComplete="username"
              placeholder="your@email.com"
              onChange={handleChange}
              value={values.email || ''}
              className={errors.email && 'error'}
            />
            {errors.email && (
              <div className="message error attached">{errors.email}</div>
            )}
          </Form.Field>

          <Form.Field>
            <Input
              label="Password"
              id="password"
              fluid
              name="password"
              type={hidePassword ? 'password' : 'text'}
              autoComplete="new-password"
              action={
                <Button
                  onClick={() => {
                    setHidePassword(!hidePassword)
                  }}
                  type="button"
                >
                  <Icon name={hidePassword ? 'hide' : 'unhide'} />
                </Button>
              }
              onChange={handleChange}
              value={values.password || ''}
              className={(errors.password || errors.rePassword) && 'error'}
            />
            {errors.password && (
              <div className="message error attached">{errors.password}</div>
            )}
          </Form.Field>

          <Form.Field>
            <Input
              label="Re-enter Password"
              id="rePassword"
              fluid
              name="rePassword"
              type={hidePassword ? 'password' : 'text'}
              autoComplete="new-password"
              onChange={handleChange}
              value={values.rePassword || ''}
              className={errors.rePassword && 'error'}
            />
            {errors.rePassword && (
              <div className="message error attached">{errors.rePassword}</div>
            )}
          </Form.Field>
          <RecaptchaWrapper>
            <Reaptcha
              sitekey={process.env.GATSBY_GOOGLE_CAPTCHA_SITEKEY}
              onVerify={onCaptchaVerified}
            />
          </RecaptchaWrapper>
          {captchaError && !captcha && (
            <div className="message error attached">{captchaError}</div>
          )}

          <div className="form-footer">
            <Button type="submit" className="button-primary">
              Register
            </Button>
          </div>
        </Segment>
      </Form>
    </FormStyles>
  )
}

export default RegisterForm

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Email address is required'
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid'
  }
  if (!values.password) {
    errors.password = 'Password is required'
  } else if (values.password !== values.rePassword) {
    errors.rePassword = 'Passwords do not match'
  } else if (values.password.length < 6) {
    errors.password = 'Password should be at least 6 characters'
  }
  return errors
}
