/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */

import React, {useContext, useEffect} from 'react'
import {navigate} from 'gatsby'
import PageHeader from '../components/Layout/PageHeader'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import RegisterForm from '../components/RegisterForm'
import {Inner} from '../components/styles'
import AuthContext from '../components/Context/AuthContext'
import CartContext from '../components/Context/CartContext'

const Register = ({location}) => {
  const {getCartContents} = useContext(CartContext)
  const {loggedInState} = useContext(AuthContext)

  /* This should prevent loading Login page when already logged in */
  useEffect(() => {
    if (loggedInState === true) {
      navigate(`/profile/`)
    }
  }, [loggedInState])

  const registerCallback = busy => {
    if (!busy) {
      navigate(`/profile/`, {state: {showNotice: 'register'}})
    }
    getCartContents()
  }

  return (
    <Layout location={location}>
      <SEO location={location} title="Register" />
      <PageHeader location={location}>Create a New Account</PageHeader>
      <Inner>
        <RegisterForm registerCallback={registerCallback} location={location} />
      </Inner>
    </Layout>
  )
}

export default Register
